.alert {
    &-warning {
        color: $yellow-50;
    }

    &-icon {
        display: flex;
        align-items: center;

        [class^='ic-'],
        [class*=' ic-'] {
            margin-right: 0.5rem;
            @include rfs(16);
        }
    }
}
