//avatar
.avatar {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  &-xxl {
    width: 6rem;
    height: 6rem;
    overflow: hidden;

    img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
      border-radius: 0.25rem;
    }
  }
  &-xl {
    @extend .avatar;
    width: 4rem;
    height: 4rem;
  }
  &-lg {
    @extend .avatar;
    width: 3rem;
    height: 3rem;
  }
  &-md {
    @extend .avatar;
    width: 2rem;
    height: 2rem;
  }
  &-sm {
    @extend .avatar;
    width: 1.5rem;
    height: 1.5rem;
  }
  &-collection {
    display: flex;
    align-items: center;
    li {
      &:not(:last-child) {
        margin-right: -0.75rem;
        .avatar {
          border: 2px solid $white;
          box-sizing: border-box;
          border-radius: 50%;
        }
      }
    }
  }
}

.alert {
  @include rfs(14);
  border: 1px dashed;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border-radius: $alert-border-radius;
  border: $alert-border-width dashed;

  [class^="ic-"],
  [class*=" ic-"] {
    font-size: 0.75rem;
  }
}

//alignment

.align {
  display: flex;
  &-vertical {
    display: flex;
    align-items: center;
  }
  &-baseline-middle {
    vertical-align: -webkit-baseline-middle !important;
  }
}

//icon size

//moves from small to large

.scrollable {
  @include scrollable;

  &-blue {
    @include scrollable(lighten($info, 25%));
  }
}

.flex {
  &-1 {
    flex: map-get($flex, one);
  }
  &-2 {
    flex: map-get($flex, two);
  }
  &-3 {
    flex: map-get($flex, three);
  }
}

.radius {
  &-top {
    &-0 {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}

.btn-group {
  .btn,
  a {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

//border classes

.card-100 {
  height: 100%;
  margin-bottom: 16px;

  .card-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .tab-pane {
    flex-direction: column;
    flex-grow: 1;

    &.active {
      display: flex;
    }
  }
}

.h-0 {
  height: 0;
}

.doc {
  &-info {
    display: flex;
    align-items: center;

    [class^="ic-"],
    [class*=" ic-"] {
      font-size: 1.25rem;
      color: $info;
      margin-right: 0.5rem;
    }
  }
}

.divider {
  height: 1px;
  background-color: $cool-gray-300;
  margin: 0.5rem 0;
}
$directions: top, right, bottom, left;

.media-wrapper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.border {
  &_right {
    border-right: 1px solid $blue-10;
    @include media-breakpoint-down(md) {
      border: unset;
    }
  }
  &_bottom {
    border-bottom: 1px solid $cool-gray-300;
  }
  &_top {
    border-top: 1px solid $cool-gray-400;
  }
}

.inner {
  &-collapse {
    margin-left: -1.125rem;
  }
}

.doc-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
