.badge-dot {
    @each $label, $value in $theme-colors {
        &-#{$label} {
            position: relative;
            display: inline-block;
            padding-left: 1rem;
            font-size: 0.875rem;
            color: $value;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 0.5rem;
                height: 0.5rem;
                background-color: $value;
                border-radius: 50%;
            }
        }
    }
}

.badge {
    &-remove {
        background: $cool-gray-200;
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: center;
        &:not(:first-child) {
            margin-left: 0.5rem;
        }
        .btn-icon-only {
            width: 1rem;
            height: 1rem;
            margin-left: 0.5rem;
            i {
                @include rfs(8);
            }
        }
    }
}
