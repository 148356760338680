.login {
  &-wrapper {
    position: relative;
    // background-image: url("../../images/login-bck.jpg");
    background-color: $primary;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding: 12px;
    overflow-y: auto;
    // top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: " ";
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.5;
      position: absolute;
    }
  }

  &-box {
    width: 860px;
    min-height: 400px;
    z-index: 2;
    // overflow: hidden;
    position: relative;

    &-left {
      background-color: $blue-60;
      color: white;
      // padding: 2rem 1.5rem;
      padding-bottom: 2rem;
      position: relative;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    &-right {
      display: flex;
      justify-content: center;
      padding: 2rem 1.5rem;
      padding-bottom: 3rem;
      background-color: white;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;

      @include media-breakpoint-down(sm) {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      & form {
        width: 100%;
      }
    }

    .logo-box {
      margin-bottom: 2rem;
      border-radius: 4px;
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      align-items: center;
      @include media-breakpoint-down(sm) {
        justify-content: flex-start;
        align-items: center;
      }
      .logo {
        height: 2.5rem;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      h6 {
        padding-right: 3.5rem;
        padding-left: 0.5rem;
      }
    }

    .login {
      &-info {
        padding: 0 1.5rem;
      }
      &-welcome {
        background-color: lighten($theme-dark-blue, 20%);
        padding: 2rem 1.5rem;
        margin-bottom: 1.5rem;
        border-top-left-radius: 0.5rem;

        h6 {
          line-height: 1.5;
          font-weight: normal;
          font-size: 1rem;
        }
      }
      &-icon {
        background-color: $blue;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        [class^="ic-"],
        [class*=" ic-"] {
          color: white;
        }
      }
      &-image {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0.08;
      }

      &-lang {
        width: 4rem;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 50px;
      }
    }

    .password {
      cursor: pointer;
    }

    .captcha {
      width: 6rem;
      object-fit: cover;
    }
  }
  &-helper {
    position: absolute;
    @include rfs(12);
    bottom: 1rem;

    &:hover {
      text-decoration: underline;
    }

    &.user-manual {
      right: 1.5rem;
    }
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &-heading {
    color: $blue;
  }
  &-card {
    // background-color: $white;
    width: 100%;
    max-width: 360px;
    margin: auto;
    padding: 1rem;
    img {
      height: 80px;
      margin: auto;
      display: block;
    }
  }
  &-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .form-group {
      margin-bottom: 0;
    }
  }

  &__02 {
    background-color: $gray-200;
    .heading {
      color: $red;
      font-weight: 400;
      @include des;
      margin-top: 0.5rem;
    }
    .dept {
      color: $gray-700;
      @include rfs(1rem);
      font-weight: 800;
      @include media-breakpoint-up(xl) {
        white-space: nowrap;
      }
    }

    .address {
      color: $gray-700;
      font-weight: 400;
      @include rfs(0.75rem);
    }

    .signup {
      color: $blue;
      font-weight: 400;
      margin-top: 3.5rem;
      @include media-breakpoint-down(sm) {
        margin-top: 2rem;
      }
    }

    .form-group {
      label,
      a {
        @include rfs(0.75rem);
        color: $gray-400;
      }
    }
    .forgotPassword {
      position: absolute;
      top: 0.25rem;
      right: 0;
    }

    .about {
      font-weight: 800;
      color: $white;
      @include rfs(1.5rem);
      margin-bottom: 2rem;
      padding: 2rem 1.5rem 0;
    }

    .credentials {
      position: relative;
      padding: 3rem;
      height: 100%;
      background-color: $white;
      @include media-breakpoint-down(lg) {
        padding: 1.5rem;
      }
      .form-group.button {
        padding-bottom: 3.5rem;
      }

      footer {
        color: $gray-300;
        @include rfs(0.75rem);
        font-weight: 400;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1rem;

        .faq {
          white-space: nowrap;
        }
      }
    }

    .list-process {
      li {
        a {
          @include h6;
          background-color: $red;
          padding: 0.75rem 1.5rem;
          border: none;
          border-radius: 4px 4px 0px 0px;

          color: rgba($white, 0.6);
          &:hover {
            color: $white;
          }
          &.active {
            color: $red;
            background-color: $white;
            @include hover-focus-active {
              color: $red;
            }
          }
        }
      }
    }
  }

  &--footer {
    color: $gray-300;
    @include rfs(0.75rem);
    font-weight: 400;
    bottom: 1rem;
    .tab {
      width: 100%;
      background-color: $gray-200;
      padding: 0.5rem 2rem;
      position: absolute;
      bottom: 0;
    }
  }

  &-helpline {
    align-self: flex-start;
    i {
      // @include rfs(16);
    }
    p {
      @include rfs(12);
      // margin-bottom: 0.75rem;
    }
  }
}

.register {
  &-box {
    width: 600px;

    @include media-breakpoint-down(md) {
      align-self: flex-start;
    }

    .logo-box {
      margin-bottom: 2rem;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .logo {
        height: 3.5rem;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      h6 {
        padding-right: 3.5rem;
        padding-left: 1rem;
      }
    }

    .login-box-right {
      border-radius: 0.5rem;
    }
  }
}
