// @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

/* //customizing bootstrap */
@import 'assets/scss/abstracts/variables';

@import 'assets/scss/frameworks/cbootstrap';
@import 'react-circular-progressbar/dist/styles.css';
// @import '../node_modules/react-table/src/';

@import 'assets/icons/icon';

@import 'assets/scss/abstracts/mixins';
@import 'assets/scss/abstracts/functions';

@import 'assets/scss/base/base';
@import 'assets/scss/base/animations';
@import 'assets/scss/base/typography';
@import 'assets/scss/base/utilities';

@import 'assets/scss/layouts/login';
@import 'assets/scss/layouts/shortcut';
@import 'assets/scss/layouts/dashboard';

@import 'assets/scss/layouts/header';
@import 'assets/scss/layouts/footer';

/* //@import "assets/scss/components/approval"; */
@import 'assets/scss/components/card';
@import 'assets/scss/components/list';
@import 'assets/scss/components/buttons';

/* //@import "assets/scss/components/register"; */
@import 'assets/scss/components/table';
@import 'assets/scss/components/tabs';
@import 'assets/scss/components/form';
@import 'assets/scss/components/dropdown';
@import 'assets/scss/components/modal';
@import 'assets/scss/components/pagination';
@import 'assets/scss/components/alert';
//@import "assets/scss/components/slider";
// @import "assets/scss/components/mail"; */
@import 'assets/scss/components/notification';
@import 'assets/scss/components/badge';
@import 'assets/scss/components/image';
@import 'assets/scss/components/flex';
@import 'assets/scss/components/grid';

@import 'assets/scss/layouts/sidebar';
// @import 'assets/scss/components/reactcustom';

// React Custom CSS
label.required {
    position: relative;

    &::after {
        content: '*';
        position: absolute;
        right: -10px;
        top: -1px;
        color: red;
    }
}

.blinking {
    animation: blinkingText 0.5s infinite;
}

@keyframes blinkingText {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.6;
    }

    49% {
        opacity: 0.4;
    }

    60% {
        opacity: 0.5;
    }

    99% {
        opacity: 0.95;
    }

    100% {
        opacity: 1;
    }
}

.spinner {
    animation: spin infinite 500ms linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.slick-prev:before, .slick-next:before {
    color: #252721 !important;
  }

  .pagebreak {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
}

.disabled {
    cursor: not-allowed;
    color: rgb(223, 71, 89);
}

.aLink {
    font-size: 0.75rem;
}

.link-text {
    @include rfs(12);
    bottom: 1rem;

    &:hover {
      text-decoration: underline;
    }

    &.user-manual {
      right: 1.5rem;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}