.btn {
  &-sm {
    @include small;
  }
  &-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    [class^="ic-"],
    [class*=" ic-"] {
      margin-right: 0.5rem;
      font-size: 0.75rem;
      opacity: 0.8;
    }

    &-only {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      @include rfs(0.75rem);

      width: 1.5rem;
      height: 1.5rem;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      [class^="ic-"],
      [class*=" ic-"] {
        font-size: 0.75rem;
      }
    }
  }

  &-data {
    .data {
      border-left: 1px solid $gray-200;
      margin-left: 0.375rem;
      padding-left: 0.375rem;
      color: $info;
    }
  }

  &-action {
    padding: map-get($action-btn, py) map-get($action-btn, px);
    background-color: map-get($action-btn, bgColor);
    color: map-get($action-btn, color);

    &:hover,
    &:focus {
      background-color: map-get($action-btn, hoverBgColor);
      color: map-get($action-btn, hoverColor);
    }
  }

  &-outline {
    border-color: $cool-gray-400;
    color: $cool-gray-600;
    background-color: $white;

    @each $label, $value in $information-color {
      &-#{$label} {
        background-color: $white;
        border-color: $value;
        color: $value;

        &:hover,
        &:focus {
          background-color: $value;
          color: $white;
        }
      }
    }
  }

  &.outline {
    &-hover {
      @each $label, $value in $information-color {
        &-#{$label} {
          &:hover,
          &:focus {
            color: $value;
            border-color: $value;
          }
        }
      }
    }
  }
  &-tab {
    position: absolute;
    top: 0;
    width: 54px;
    height: 28px;
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
    .btn {
      background-color: $white;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08),
        0px 1px 2px rgba(0, 0, 0, 0.04);
      [class^="ic-"],
      [class*=" ic-"] {
        color: $primary;
        font-size: 0.5rem;
      }
    }
    &-right {
      right: 0;
      text-align: right;
      background: linear-gradient(
        270deg,
        white 0%,
        rgba(255, 255, 255, 0) 100%
      );
      .btn {
        margin-right: 0.25rem;
      }
    }
    &-left {
      left: 0;
      background: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      .btn {
        margin-left: 0.25rem;
      }
    }
  }
  &-highlighted {
    background: unset;
    padding: 0;
    border: unset;
    display: flex;
    .default,
    .data {
      padding: 0.375rem 1rem;
    }
    .default {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      background-color: $info;
    }
    .data {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      background-color: #00479b;
    }
  }
  // &-warning {
  //     background-color: $yellow-50;
  //     border-color: $yellow-50;
  // }
}
