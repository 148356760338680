@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?cdy5bg');
    src: url('fonts/icomoon.eot?cdy5bg#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?cdy5bg') format('truetype'), url('fonts/icomoon.woff?cdy5bg') format('woff'),
        url('fonts/icomoon.svg?cdy5bg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='ic-'],
[class*=' ic-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ic-add:before {
    content: '\e900';
}
.ic-add-box-filled:before {
    content: '\e901';
}
.ic-add-box-outline:before {
    content: '\e902';
}
.ic-add-circular-filled:before {
    content: '\e903';
}
.ic-add-circular-outline:before {
    content: '\e904';
}
.ic-add-default:before {
    content: '\e905';
}
.ic-alert:before {
    content: '\e906';
}
.ic-alert-filled:before {
    content: '\e907';
}
.ic-arrow-down:before {
    content: '\e908';
}
.ic-arrow-down-circle-fill:before {
    content: '\e909';
}
.ic-arrow-left:before {
    content: '\e90a';
}
.ic-arrow-left-circle-fill:before {
    content: '\e90b';
}
.ic-arrow-right:before {
    content: '\e90c';
}
.ic-arrow-right-circle-fill:before {
    content: '\e90d';
}
.ic-arrow-up:before {
    content: '\e90e';
}
.ic-arrow-up-circle-fill:before {
    content: '\e90f';
}
.ic-attachments:before {
    content: '\e910';
}
.ic-bank:before {
    content: '\e911';
}
.ic-calendar:before {
    content: '\e912';
}
.ic-call-filled:before {
    content: '\e913';
}
.ic-checkbox-active:before {
    content: '\e914';
}
.ic-checkbox-active-changed:before {
    content: '\e915';
}
.ic-checkbox-default:before {
    content: '\e916';
}
.ic-check-circle:before {
    content: '\e917';
}
.ic-checkmark:before {
    content: '\e918';
}
.ic-clipboard-outline:before {
    content: '\e919';
}
.ic-close:before {
    content: '\e91a';
}
.ic-close-circular-filled:before {
    content: '\e91b';
}
.ic-close-circular-outline:before {
    content: '\e91c';
}
.ic-close-default:before {
    content: '\e91d';
}
.ic-dashboard-filled:before {
    content: '\e91e';
}
.ic-dashboard-outline:before {
    content: '\e91f';
}
.ic-deactivate:before {
    content: '\e920';
}
.ic-delete:before {
    content: '\e921';
}
.ic-delete-cross:before {
    content: '\e922';
}
.ic-delete-filled:before {
    content: '\e923';
}
.ic-delete-outline:before {
    content: '\e924';
}
.ic-delete-outline-cross:before {
    content: '\e925';
}
.ic-dropdown:before {
    content: '\e926';
}
.ic-edit:before {
    content: '\e927';
}
.ic-edit-outline:before {
    content: '\e928';
}
.ic-file:before {
    content: '\e929';
}
.ic-filter:before {
    content: '\e92a';
}
.ic-graph-filled:before {
    content: '\e92b';
}
.ic-graph-outline:before {
    content: '\e92c';
}
.ic-help-filled:before {
    content: '\e92d';
}
.ic-help-outline:before {
    content: '\e92e';
}
.ic-hide:before {
    content: '\e92f';
}
.ic-history:before {
    content: '\e930';
}
.ic-home-filled:before {
    content: '\e931';
}
.ic-home-outline:before {
    content: '\e932';
}
.ic-image-outline:before {
    content: '\e933';
}
.ic-info:before {
    content: '\e934';
}
.ic-information:before {
    content: '\e935';
}
.ic-keyboard-outline:before {
    content: '\e936';
}
.ic-line-arrow-down:before {
    content: '\e937';
}
.ic-line-arrow-left:before {
    content: '\e938';
}
.ic-line-arrow-right:before {
    content: '\e939';
}
.ic-line-arrow-top:before {
    content: '\e93a';
}
.ic-loading:before {
    content: '\e93b';
}
.ic-logout:before {
    content: '\e93c';
}
.ic-mail-filled:before {
    content: '\e93d';
}
.ic-mail-outline:before {
    content: '\e93e';
}
.ic-menu:before {
    content: '\e93f';
}
.ic-more:before {
    content: '\e940';
}
.ic-pin-filled:before {
    content: '\e941';
}
.ic-pin-outline:before {
    content: '\e942';
}
.ic-print:before {
    content: '\e943';
}
.ic-product-filled:before {
    content: '\e944';
}
.ic-product-outline:before {
    content: '\e945';
}
.ic-refresh:before {
    content: '\e946';
}
.ic-remove:before {
    content: '\e947';
}
.ic-remove-box-filled:before {
    content: '\e948';
}
.ic-remove-box-outline:before {
    content: '\e949';
}
.ic-remove-circular-filled:before {
    content: '\e94a';
}
.ic-remove-circular-outline:before {
    content: '\e94b';
}
.ic-remove-default:before {
    content: '\e94c';
}
.ic-search:before {
    content: '\e94d';
}
.ic-settings-filled:before {
    content: '\e94e';
}
.ic-settings-outline:before {
    content: '\e94f';
}
.ic-setup:before {
    content: '\e950';
}
.ic-show:before {
    content: '\e951';
}
.ic-sort:before {
    content: '\e952';
}
.ic-transactions:before {
    content: '\e953';
}
.ic-update:before {
    content: '\e954';
}
.ic-upload:before {
    content: '\e955';
}
.ic-user-filled:before {
    content: '\e956';
}
.ic-user-outline:before {
    content: '\e957';
}
.ic-users:before {
    content: '\e958';
}
