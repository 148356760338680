.grid {
  display: grid;
  &--card-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-admitcard {
    margin: 0 -1rem;
    border-top: 1px solid $cool-gray-400;
    .details {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $cool-gray-400;
      border-left: 1px solid $cool-gray-400;
    }
  }
  &-admitcard-job-details {
    grid-template-columns: repeat(4, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-admitcard-individual-details {
    grid-template-columns: 25% 25% 50%;

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-room-center-details {
    grid-template-columns: repeat(3, 1fr);
  }
}
