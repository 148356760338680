.shortcut{
    width: var(--shorcut-width);
    background-color: $cyan;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding:.5rem .25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    &-logo{
        width: 2rem;
        height: 2rem;
        display: block;
        margin: auto;


        img{
            max-width: 100;
            height: 100%;
        }
    }
    &-icon{
        display: flex;
        align-items: center;
        color: $blue-100;
        width: 100%;
        @include h5;
        transition: all .3s;
        @include hover{
            color: $yellow;
            cursor: pointer;
        }

    }
    &-dropdown{
        .dropdown-menu{
            
            left: 100%!important;
            
        }
    }

  }