$header-bg: white;
.header {
  background-color: map-get($header, bgColor);
  padding: 0.5rem 1rem 0.5rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    padding: 1rem;
  }

  .toggler {
    color: white;
    margin-right: 1rem;
    cursor: pointer;
  }

  .auth {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    h6 {
      margin-left: 0.5rem;
      color: map-get($header, userColor);
      @include des;
      font-weight: 400;
    }

    i,
    svg {
      color: $white;
      font-size: 0.5rem;
    }
    .avatar-md {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .title {
    font-size: 1rem;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--title {
    display: flex;
    align-items: center;

    max-width: 60%;
    width: 60%;
    flex: 0 0 60%;
  }
}
.logo {
  max-height: map-get($header, logoSize);
}

.toggled {
  header {
    @include media-breakpoint-up(md) {
      padding-left: 1rem;
    }

    transition: 0.25s padding-left $time-func-1;
  }
}
