.img {
  &-wrapper {
    width: 150px;
    height: 150px;
    background-color: $white;
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: $box-shadow;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.img-sign {
      img {
        object-fit: contain;
      }
    }
  }
  &-profile {
    position: relative;
    cursor: pointer;
    overflow: unset;
    @include hover {
      .img {
        &-overlay {
          display: block;
          &-text {
            display: block;
          }
          &-close {
            display: block;
          }
        }
      }
    }
    &-upload {
      width: 150px;
      height: 150px;
    }
  }
  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(194, 210, 239, 0.86);
    display: none;
    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $gray-400;
      display: none;
      cursor: pointer;
      label{
          cursor: pointer;
      }
    }
    &-close {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      border-radius: 50%;
      background-color: white;
      box-shadow: $box-shadow;

      width: 1rem;
      height: 1rem;
      display: none;
      [class^="ic-"],
      [class*=" ic-"] {
        color: $gray-400;
        font-size: 0.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
