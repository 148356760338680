.table {
  width: 100%;
  margin-bottom: 0;
  // overflow: hidden;
  border-radius: $table-border-radius;

  thead {
    th,
    td {
      @include rfs(0.875rem);
      color: $table-head-color;
    }
    th {
      font-weight: 600;
      white-space: nowrap;
      border: 1px solid $table-head-border-color;
    }
  }

  tbody {
    td {
      @include rfs(0.875rem);
      vertical-align: middle;
      border-top: 1px solid $gray-100;
      color: $gray-900;
    }

    .ic-more {
      transform: rotate(90deg);
      display: block;
      // margin-left: auto;
      // padding-left: 1.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-basic {
    thead {
      background-color: $blue;
      color: $white;
    }
  }

  &-dropdown {
    .dropdown-menu {
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      padding: 0;
      border-color: transparent;

      .dropdown-item {
        font-weight: 600;
        color: $secondary;
        &:hover {
          background-color: #f8f8fb;
          color: $blue;
        }
      }
    }
  }

  &-dialog {
    width: 190px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $blue;
    color: white;
    padding: 0.5rem;
    box-shadow: 0px 2px 4px $gray-100;
    border-radius: 2px;
    display: none;
  }

  td {
    white-space: nowrap;
    &.toggle {
      position: relative;
      @include hover {
        .table-dialog {
          display: block;
        }
      }
    }
  }

  &-01 {
    border: 1px solid $border-color;
    tbody {
      td {
        border: none;
      }
    }
    tfoot {
      td {
        border: 1px solid $border-color;
        white-space: nowrap;
        vertical-align: middle;
      }
      &.borderless {
        td {
          border: none;
          border-top: 1px solid $border-color;
        }
      }
    }
  }

  &-02 {
    thead {
      tr {
        th {
          background-color: $table-head-bg;
          text-align: left;
          border-right: unset;
        }
      }
    }
    tr {
      td {
        .dropdown-table {
          &.dropdown-toggle::after {
            display: none;
          }
          &.btn-secondary {
            color: unset;
            background-color: unset;
            border: unset;
            &:active {
              color: unset;
              background-color: unset;
              border: unset;
            }
          }
        }
      }
    }
  }

  &-borderless {
    border: unset !important;
  }

  &-rounded {
    thead {
      tr {
        :first-child {
          border-radius: 4px 0 0 0;
        }
        :last-child {
          border-radius: 0 4px 0 0;
        }
        :only-child {
          border-radius: 4px 4px 0 0;
        }
      }
    }
    // thead {
    //   tr {
    //     border-radius: 4px 4px 0 0;
    //   }
    // }
  }

  &-react {
    .rt-table {
      background-color: $table-bg;
      border-color: $table-border-color;
      border-collapse: collapse;
      .rt {
        &-thead {
          .rt-tr {
            text-align: left;
          }
          .rt-th {
            padding: $table-cell-padding;
            font-size: 0.833rem;
            font-weight: 600;
            color: $table-head-color;
            white-space: nowrap;
            border-color: $table-head-border-color;
            background-color: $table-head-bg;
          }
        }
        &-tbody {
          .rt-td {
            font-size: 0.833rem;
            padding: $table-cell-padding;
          }
        }
      }
    }
    &.borderless {
      border: none;
      .rt {
        &-th {
          border: none;
        }
        &-td {
          border: none;
        }
      }
    }
  }

  .actions {
    color: $cool-gray-600;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $cool-gray-700;
    // margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;

    // @include media-breakpoint-down(sm) {
    //   margin-top: 0.75rem;
    // }
  }

  svg {
    fill: rgba($primary, 0.8);
  }

  &-present {
    tbody {
      // border-right: 1px solid $cool-gray-400;
      // border-left: 1px solid $cool-gray-400;
      tr {
        td {
          border: 1px solid $cool-gray-400;

          &:last-child {
            min-width: 3rem;
          }
        }
      }
    }

    &-categorywise {
      tr {
        td {
          border: 1px solid $cool-gray-400;
          height: 1.975rem;
        }
      }
    }
  }
}

.status {
  display: flex;
  align-items: center;
  @include des;
}

.filter {
  &-wrapper {
    transition: all 0.5s $time-func-1;
    position: absolute;
    top: 120%;
    right: 0;
    // transform: translateX(-5%);
    display: block;
    z-index: 10;

    &.hide {
      transform: translateX(0%);
      display: none;
    }
  }
  &-container {
    background-color: white;
    box-shadow: $dropdown-box-shadow;
    border-radius: $card-border-radius;
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 1rem;
    width: 300px;
  }
}
