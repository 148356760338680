@mixin list-bordered(
  $hover,
  $hover-text,
  $px: 1rem,
  $py: 0.75rem,
  $color: $secondary
) {
  li {
    padding: $px $py;

    &:not(:last-child) {
      border-bottom: 1px solid $color;
    }

    @include hover {
      background-color: $hover;
      color: $hover-text;
    }
  }
}
@mixin list-btnlist($px, $py, $activeBg, $activeColor, $color, $border) {
  @include list-unstyled;
  margin: 0;

  li {
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 2px;
    }

    a {
      display: block;
      font-style: normal;
      font-weight: 600;
      padding: $py $px;
      border: 1px solid $border;
      box-sizing: border-box;
      color: $color;
      @include des;

      &.active {
        background-color: $activeBg;
        color: $activeColor;
        border-color: $activeBg;
      }
    }
  }
}

.list {
  @include list-unstyled;
  list-style: none;
  padding: 0;
  margin: 0;

  &-sidebar {
    margin-bottom: 1rem;
    li {
      p {
        color: map-get($sidebar, headerColor);
      }
      a {
        margin-bottom: 0.25rem;
      }
    }

    .hasSub {
      & + .list-sidebar {
        padding-left: 1.25rem;
      }
    }

    a {
      display: flex;
      align-items: center;
      color: map-get($sidebar, linkColor);
      border-radius: 2px;
      // font-size: 0.75rem;
      @include rfs(14);
      padding: 0.25rem 0.5rem;
      // @include lhCrop(1.2); //line-height: 1.2
      line-height: 1.5;

      &.active {
        background-color: $blue-300;
        color: $blue-400;
      }
    }
  }

  &-withcount {
    padding: 0 1.5rem;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: map-get($sidebar, linkColor);
      @include des;
      padding: 0.5rem;
      // @include lhCrop(1.2); //line-height: 1.2
      line-height: 1;

      &.active {
        background-color: $blue-300;
        border-radius: 4px;
        color: $blue-400;

        @include hover {
          color: $blue-400;
        }
      }

      &:hover {
        color: $blue-300;
      }
    }
  }

  &__inline {
    display: flex;

    @include media-breakpoint-up(md) {
      align-items: center;
    }

    & > * {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .ic-members,
      .ic-chat {
        @include rfs(1rem);
      }
    }
  }

  &-links {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $gray-700;
      border-bottom: 1px solid $gray-100;

      &.active {
        background-color: $blue-400;
        color: white;

        @include hover {
          color: white;
        }
      }

      @include hover {
        color: $cyan;
        cursor: pointer;
      }
    }

    a {
      @include des;
      padding: 0.5rem;
      line-height: 1;
    }

    .close {
      font-size: 0.5rem;

      @include hover {
        color: $red;
      }
    }
  }

  &-03 {
    a {
      display: block;
      padding: 0.5rem 1.5rem;
      @include des;
      color: black;
      background-color: $gray-200;

      &.active {
        color: white;
        background-color: $blue;
      }
    }
    &_scrolllg {
      @include media-breakpoint-down(lg) {
        display: flex;
        margin-bottom: 1rem;
        @include scrollable($blue);
        padding-bottom: 0.5rem;
      }

      li {
        @include media-breakpoint-down(lg) {
          flex-shrink: 0;
          margin-right: 1rem;
        }
      }
    }
  }

  &-process {
    display: flex;
    border-bottom: 1px solid $gray-100;
    flex-wrap: wrap;

    li {
      & > a {
        display: block;
        @include des;
        font-weight: 600;
        border-bottom: 2px solid transparent;
        position: relative;
        color: $gray-400;
        padding: 0.5rem 1rem;

        &.active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          color: $white;
          background-color: $blue;

          @include hover-focus-active {
            color: white;
          }
        }

        @include hover {
          cursor: pointer;
          color: $blue;
        }
      }
    }
    &.scroll {
      flex-wrap: nowrap;
      @include scrollable;

      li {
        flex-shrink: 0;
      }
    }
  }

  &-tree {
    overflow: auto;
    .list-group-item {
      display: flex;
      align-items: center;
      // border-bottom: 1px solid $gray-100;
      padding: 6px 12px;
      @include des;
      background-color: $cool-gray-100;
      margin-bottom: 2px;
      border-radius: 2px;

      &[aria-expanded="true"] {
        background-color: $blue;
        color: $white;

        [class^="ic-"],
        [class*=" ic-"] {
          color: $white;
        }
      }

      .state-icon {
        margin-right: 0.75rem;
        cursor: pointer;
        color: $gray-300;
      }

      .item-icon {
        margin-right: 0.5rem;
      }

      i {
        @include des;
      }

      &:not([aria-expanded="true"]):hover {
        background-color: $cool-gray-200;
        color: inherit;
      }

      &.hasSub {
        position: relative;
        padding-left: 28px;

        &::after {
          content: "\e958";
          font-family: "icomoon";
          position: absolute;
          font-size: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
          left: 8px;
        }

        &[aria-expanded="false"] {
          &::after {
            content: "\e95b";
          }
        }
      }

      [class^="ic-"],
      [class*=" ic-"] {
        color: $blue;
        transform: translateY(-1px);
        margin-right: 8px;
        // &.gray {
        //   color: $cool-gray-600;
        // }
      }
    }

    ul {
      padding-left: 1rem;
    }
  }

  &-separator {
    & > {
      * {
        &:last-child {
          padding-left: 1rem;
          margin-left: 1rem;
          position: relative;

          &::before {
            content: "";
            width: 1px;
            height: 1rem;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $cool-gray-200;
          }
        }
      }
    }
  }

  &-legend {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    .box {
      position: relative;
      width: 13px;
      height: 13px;
      border-radius: 2px;
      padding: 1px;
      border: 1px solid $cool-gray-600;
      &-indeterminate {
        &:before {
          content: " ";
          border: 1px solid $cool-gray-600;
          width: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &-dot {
    li {
      &:not(:last-child) {
        position: relative;
        margin-right: 0.75rem;
        padding-right: 0.75rem;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -0.125rem;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background-color: $gray-300;
          border-radius: 50%;
        }
      }
    }
  }

  &--job-deets {
    @include rfs(14);
    color: $cool-gray-600;
  }

  &-reset {
    padding-left: 1rem;
    margin-bottom: 0;

    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &--payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    li {
      display: block;
      width: 150px;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    a {
      display: inline-block;
      border: 1px solid $gray-100;
      border-radius: 4px;
      padding: 0.5rem 2rem;
      width: 100%;
      text-align: center;
      &:hover {
        color: unset;
        border-color: $info;
      }

      &.active {
        border-color: $info;
      }
    }

    [class^="ic-"],
    [class*=" ic-"] {
      font-size: 2rem;
      // color: $cool-gray-700;
    }
  }

  // for devnagiri numbering
  &-login {
    li {
      display: flex;
      align-items: flex-start;
      @include des;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .marker {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        flex: 0 0 1rem;
        text-align: center;
        border-radius: 50%;
        background: white;
        color: $info;
        margin-right: 0.5rem;
        padding: 0 4px;
      }
    }
  }
  &-admitcard {
    list-style-type: devanagari;
    li {
      @include des;

      &:not(:last-child) {
        margin-bottom: 0.625rem;
      }
      p {
        margin-left: 0.5rem;
      }
    }
  }
  &-bullet {
    @include list-unstyled;
    li {
      position: relative;

      &:before {
        content: "";
        width: 0.45rem;
        height: 0.45rem;
        background-color: $info;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 0.35rem;
        left: 0;
      }
      padding-left: calc(0.5rem + 1rem);
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
    // a {
    //     box-shadow: 0px 4px 8px rgba(196, 218, 255, 0.48), 0px 16px 32px rgba(196, 218, 255, 0.48);
    //     padding: 1rem 1.5rem;
    //     border-radius: 0.5rem;
    //     color: $brand-secondary;
    //     font-family: $font-family-display;
    //     font-weight: 500;
    //     @include rfs(22);
    //     &.active {
    //         box-shadow: none;
    //     }
    // }

    &.bank-deposit-details {
      margin-bottom: 0;
      li {
        padding-left: 1rem;
        &:before {
          content: "";
          width: 0.3rem;
          height: 0.3rem;
          background-color: $warning;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 0.35rem;
          left: 0;
        }
      }
    }
  }

  // &--bordered {
  //   li {
  //     &:not(:last-child) {
  //       border-bottom: 1px solid $border;
  //     }
  //   }
  // }
  // &--detail {
  //   &-dots {
  //     display: flex;
  //     align-items: center;
  //     @include des;
  //     color: $cool-gray-600;
  //     :not(:first-child) {
  //       position: relative;
  //       margin-left: 0.5rem;
  //       padding-left: 0.5rem;

  //       &::before {
  //         content: "";
  //         position: absolute;
  //         top: 50%;
  //         left: -0.125rem;
  //         transform: translateY(-50%);
  //         width: 4px;
  //         height: 4px;
  //         background-color: $gray-300;
  //         border-radius: 50%;
  //       }
  //     }
  //   }
  // }
  &-ad-details {
    border-bottom: 1px solid $gray-100;
    margin-bottom: 0.75rem;
    @include media-breakpoint-down(sm) {
      border-right: unset;
      margin-bottom: 1rem;
      padding-right: 0;
      padding-bottom: 1rem;
      border-bottom: 1px solid $gray-100;
    }
    li {
      &:first-child() {
        // border-bottom: 1px solid $gray-100;
        p {
          margin-bottom: 0.25rem;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      p {
        margin-top: 0.25rem;
      }
    }
  }
  &-scrutinyofficer {
    &-detail {
      margin-top: 0.5rem;
      padding-bottom: 0.5rem;
      // &:not(:last-child) {
      //     border-bottom: 1px solid $gray-100;
      // }
      li {
        @include rfs(14);
        p {
          @include rfs(16);
        }
      }
    }
  }
}

.footer {
  &-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    .btn {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}
